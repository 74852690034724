import React, { useEffect, useRef } from 'react';
import { GoogleMap, withScriptjs, withGoogleMap, Marker } from 'react-google-maps';
import mapStyle from '../../../static/assets/data/dealers/styleMap.json'; 
import pinOver from '../../../static/assets/images/dealers/distibuidores_cinsa_pinOver.png';

function Map({location, ref}) {

    return(
        <GoogleMap
            ref={ref}
            defaultZoom={10}
            defaultCenter={{lat: location.data.coordinates.latitude, lng: location.data.coordinates.longitude}}
            defaultOptions={{styles: mapStyle}}
            zoom={location ? 17 : 10}
        >
            
            <Marker icon={pinOver} key={2} position={{lat: location.data.coordinates.latitude, lng: location.data.coordinates.longitude}} />
        </GoogleMap>
    )
}

function MapLg({ location, defaultLocation, overStore, overBranchoffice}) {
    const refMap = useRef(null)       

    return(
        <GoogleMap
            ref={refMap}
            defaultZoom={5.5}
            defaultCenter={{
                lat: defaultLocation.latitude, 
                lng: defaultLocation.longitude
            }}
            center= {{                
                lat: location === null ? defaultLocation.latitude : location.latitude,
                lng: location === null ? defaultLocation.longitude : location.longitude
            }} 
            
            
            zoom={ location !== null? 15 : defaultLocation.zoom}
            defaultOptions={{styles: mapStyle}}
        >
            {
                location !== null ?
                    <Marker
                        icon={pinOver}
                     //   onMouseOver={setIconMouseOver}
                        position={{lat: location.latitude, lng: location.longitude}}
                    />
                :
                null
            }
            
        </GoogleMap>
       
    )
}


export const WrappedMap = withScriptjs(withGoogleMap(Map));
export const WrappedMapLg = withScriptjs(withGoogleMap(MapLg));
