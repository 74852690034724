import React, { useState, useEffect, useRef } from 'react'
import { Picture } from "react-responsive-picture"
import Slider from "react-slick"
import SliderArrow from "../sliders/slider_arrow"
import { SliderSlickButton } from '../../components/buttons/_button_slider_slick';
import { useCurrenDevice } from "../../hooks/useCurrentDevice"
import StoresList from './stores_list'
import { WrappedMapLg } from './dealerMap';

import { pushToDataLayer } from '../gtm/pushToDataLayer'

const SliderStores = ({ _storesByState, _selectState, _initialItems, _numberItems, _campaign_name}) =>{

    const API = 'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAH-wS-2_hWwM06yxIZ1oj3XunrhqN_iSM';
    const [currentPosition, setCurrentPosition] = useState({latitude: 23.6345005, longitude: -102.5527878, zoom: 5.5});
    const [winWidth, setWinWidth] = useState(null)
    const slider = useRef(null)
    let sliderArrow = new SliderArrow()
    const bannerSectionRef = useRef(null)
    const [numberImage, setNumberImage] = useState(null)
    const [listStores, setListStores] = useState(null)
    const [currentStore, setCurrentStore] = useState(null)

    const [device] = useCurrenDevice()

    const settings = {
    customPaging: function(i) {
        return <span className="pagination__bullet"></span>
        },
        dots: true,
        dotsClass: "pagination__dots offers_dots",
        lazyLoad: true,
        infinite: false,
        speed: 500,
        slidesToShow: winWidth,
        slidesToScroll: 1,
        prevArrow: <SliderSlickButton _className="offers-button-prev" />,
        nextArrow: (
        <SliderSlickButton _className="offers-button-next" isNext={true} />
        ),
      }

      
    useEffect(() =>{
        if(_selectState){
          setNumberImage(_numberItems)

          const firstDealer =  _storesByState[0].data.dealer.dealer_name.text
          let dataFirstDealer = []

          _storesByState.map(item =>{
            if(item.data.dealer.dealer_name.text === firstDealer){
               dataFirstDealer = [
                 ...dataFirstDealer,
                 item
               ]
            }
           
          })
          setListStores(dataFirstDealer)

        }else{
          setNumberImage(_initialItems.length)
        }
        
    },[_selectState, _numberItems, _initialItems])

    useEffect(() =>{
        if(device === "mobile"){
            setWinWidth(1)
        }
        if(device === "tablet"){
          if(numberImage > 1){
            setWinWidth(2)
          }else{
            setWinWidth(1)
          }
          
        }
        if(device === "desktopMin"){
          if(numberImage > 2 ){
            setWinWidth(3)
            document.querySelector(".slider_dealer").style.width = "100%";
          }else{
            setWinWidth(numberImage)
            document.querySelector(".slider_dealer").style.width = "600px";
            return () => {
              document.querySelector(".slider_dealer").style.width = "680px"
            }
          }
      }
        if(device === "desktop"){
          if(numberImage > 2 &&  numberImage < 4){
            setWinWidth(3)
            document.querySelector(".slider_dealer").style.width = "830px";
            return () => {
              document.querySelector(".slider_dealer").style.width = "90%"
            }
          }
         else if(numberImage >= 4 ){
            setWinWidth(4)
            document.querySelector(".slider_dealer").style.width = "100%";
          }else{
            setWinWidth(numberImage)
            document.querySelector(".slider_dealer").style.width = "600px";
            return () => {
              document.querySelector(".slider_dealer").style.width = "90%"
            }
          }
        }
        if(device === "xlg"){
          if(numberImage < 3){
            setWinWidth(numberImage)
            document.querySelector(".slider_dealer").style.width = "600px";  
          }
          else if(numberImage < 5){
            setWinWidth(numberImage)
            document.querySelector(".slider_dealer").style.width = "1100px";
          }
          else{
            setWinWidth(5)
            document.querySelector(".slider_dealer").style.width = "100%"
          }
       }


    }, [device, numberImage])

    useEffect(() => {
        if (!bannerSectionRef.current) return
        sliderArrow.init(bannerSectionRef)
      }, [sliderArrow])

      useEffect(() => {
        if(_storesByState.length > 0 ){
            let result = []
            _storesByState.map((store, index) =>{
                 if(index === 0){
                     result = [store.data.store]
                     setCurrentStore( result[0].data.coordinates)
                 }
             })
         }
    }, [_storesByState ])

    const handleStore = (data, dealerName)=> {
      let dealerSelected = []
      data.map((item, i) =>{
        if(item.data.dealer.dealer_name.text === dealerName){
        
          dealerSelected = [
            ...dealerSelected, 
              item
          ]
        }
         
      })

      const coordinates = dealerSelected[0].data.store
      setCurrentStore(coordinates.data.coordinates)
      setListStores(dealerSelected)
      
      const gtmStateName = dealerSelected[0].data.store.data.state
      const gtmDealerName = dealerName
      pushToDataLayer({
        event: "deals_selected_dealer",
        campaignName: _campaign_name.text,
        dealerName: gtmDealerName,
        stateName: gtmStateName,
        eventCallback: () => {},
      })
    }

    function create_items(items){  

        let arrayImages = []
        let result = []

        items.map((item) => {
            arrayImages.push(item.data.dealer)
              result = arrayImages.filter((item, index) => {
                return arrayImages.indexOf(item) === index
            })
          }) 
          
        
        return result.map((resp, index) => {
            return (
                <div key={index} className={`container_image--dealer`} 
                onClick={() =>{handleStore(items, resp.dealer_name.text)}}>
                <Picture
                        className=""
                        src={resp.dealer_image.url}
                        alt=""
                    />
                    <p>{resp.display_name ? resp.dealer_short_name.text : null}</p>
                </div>
            )
          })
    }

  return(
    <>
      <div className="slider_dealer" ref={bannerSectionRef} >
          <Slider ref={slider} {...settings}>
              {create_items(_selectState ? _storesByState : _initialItems)}   
          </Slider>
      </div>
      <div className="detail_stores--map">
          {
            <StoresList initialItems={_initialItems} _selected={_selectState} _listStores={listStores} setCurrentStore={setCurrentStore} _campaign_name={_campaign_name}/>
          }
          <div className="c-dealers__map c_map--offers">
             <WrappedMapLg
                location={currentStore} 
                defaultLocation={currentPosition}
                googleMapURL={API}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
            />
        </div>
      </div>

    </>
  )
}

export default SliderStores
