import CssClassManager from '../../components/_helpers/_css_class_manager'

class SliderArrow  {

    sliderSectionRef = null;
    classManager = new CssClassManager();

    constructor(){

    }

    init(_sliderSectionRef){
        if(!_sliderSectionRef.current) return;
        this.sliderSectionRef = _sliderSectionRef;
        this.sliderSectionRef.current.addEventListener("mousemove", (e)=>{
            const width = window.innerWidth / 2;
            if(e.clientX > width) {
                this.classManager.remove_class(this.sliderSectionRef.current,'nav_on_prev')
                this.classManager.add_class(this.sliderSectionRef.current,'nav_on_next')
            } else if(e.clientX < width) {
                this.classManager.remove_class(this.sliderSectionRef.current,'nav_on_next')
                this.classManager.add_class(this.sliderSectionRef.current,'nav_on_prev')
            }
        })
    }
}

export default SliderArrow