import React from 'react'
import Layout from "../components/layout"

import Header from "../content/promotions/header"
import Storytelling from "../content/promotions/storytelling"
import Offers from "../content/promotions/offers"

import { usePrismDealsPage } from '../hooks/use_prims_promotions'
import "../styles/pages/promotions/promotions.scss"

const Promotions = () =>{
    const promotions = usePrismDealsPage().edges
    const data = promotions[0].node.data
    const campaignName = data.campaign_name

    return(
        <Layout>
            <Header _image={data.main_image} />
            <Storytelling _body={data.body}/>
            <Offers _stores={data.body1} _campaign_name={campaignName} />
        </Layout>
    )
}

export default Promotions