import React, {useState, useEffect} from "react";
import { Picture } from "react-responsive-picture"
import CinsaLogo from "../svg/commons/cinsa_logo";
import PhoneContact from '../svg/commons/phone_ringing'
import SvgMap from '../svg/dealers/maps';
import SvgSite from '../svg/dealers/site';

import { pushToDataLayer } from '../gtm/pushToDataLayer'

const StoresList = ({ initialItems, _listStores,  _selected, setCurrentStore, _campaign_name }) => {
   
    /* eslint-disable */
    const [dealer_image, setDealerImage] = useState(null)
    const [data, setData] = useState(null)

    useEffect(() => {
        initialItems.map(item =>{
            setDealerImage( initialItems[0].data.dealer.dealer_image.url) 
        })

    },[initialItems])

    useEffect(() => {
      if(_selected){
        setData(_listStores)
        _listStores.map(item =>{
        setDealerImage(_listStores[0].data.dealer.dealer_image.url)
      })
     }
 
   
   },[_listStores])
  
    function handleClick (e, data ) {
        if(data !== null){
            setCurrentStore(data.coordinates)

            const gtmDealerName = data.store
            const gtmStateName = data.state
            const gtmBranchName = data.branchoffice !== null ? (data.branchoffice +"_"+ data.document_id) :  (data.store +"_"+ data.document_id)
            pushToDataLayer({
                event: "deals_selected_branch",
                campaignName: _campaign_name.text,
                dealerName: gtmDealerName,
                stateName: gtmStateName,
                branchName: gtmBranchName,
                eventCallback: () => {},
            })
        }

        // const elActive = document.querySelector(".c-dealers-branchoffices__card.isActive");
        // if(elActive) {
        //     elActive.classList.remove("isActive");
        // }
        // e.target.parentElement.classList.add("isActive");
    }


    function CardList(stores, index) {
      
            const store = stores.data.store
            return(
                <div className="c-dealers-branchoffices__card branchoffices__card-promotions" key={index} >
                        <div onClick={(e) =>{handleClick(e, store.data)}}>
                            <h3>{store.data.store}</h3>
                            <p className="c-dealers-branchoffices__card--address">{store.data.address}</p>
                            <p className="c-dealers-branchoffices__card--phone">{store.data.phone}</p>
                        </div>
                    <div className="card__links">
                        <Link  _class="card__links--map" text="cómo llegar"  dealer={store.data.store}  state={store.data.state}  branch={store.data.branchoffice} id={store.data.document_id}
                          url={`http://maps.google.com/maps?&z=15&mrt=yp&t=k&q=
                                ${store.data.coordinates.latitude},
                                ${store.data.coordinates.longitude}`}>
                           <SvgMap />
                        </Link>
                        <Link  _class="card__links--site" text="Ver sitio" url={store.data.website} dealer={store.data.store}  state={store.data.state}  branch={store.data.branchoffice} id={store.data.document_id}> 
                            <SvgSite />
                        </Link>
                        <Link  _class="card__links--phone" text="Llamar" url={`tel:${store.data.phone}`} dealer={store.data.store}  state={store.data.state}  branch={store.data.branchoffice} id={store.data.document_id}>
                             <PhoneContact />
                        </Link>
                    </div>
                </div>
            )
    }

    const handleLink = (e, _name, _dealer, _state, _branch, _id) =>{
        const gtmDealer = _dealer
        const gtmState = _state
        const gtmBranch = _branch !== null ? (_branch +"_"+_id) : (_dealer +"_"+_id)

        let gtmEvent

        if(_name === "cómo llegar"){
            gtmEvent = "deals_selected_branch_geolocation"
        }else if(_name === "Ver sitio"){
            gtmEvent = "deals_selected_branch_website"
        }else if(_name === "Llamar"){
            gtmEvent = "deals_selected_branch_phone"
        }

        pushToDataLayer({
            event: gtmEvent,
            campaignName: _campaign_name.text,
            dealerName: gtmDealer,
            stateName: gtmState,
            branchName: gtmBranch,
            eventCallback: () => {},
        })
    }   

    function Link (props) {
        const { text, url, children, dealer, state, branch, id } = props;
        return (
            <a href={url} target="_blank" rel="noopener noreferrer" onClick={(e) => {handleLink(e, text, dealer, state, branch, id)}} >
                {children}
                <span>{text}</span>
            </a>
        )
    }

    return (
        <div className="c-dealers-branchoffices c-stores-list">
            <div className="c-dealers-branchoffices__image">
                <Picture
                className=""
                src={ dealer_image }
                alt=""
                /> 
            </div>
            {   _selected && data !== null ? 
                data.map((stores, index) => CardList(stores, index)) : 
                initialItems.map((stores, index) => CardList(stores, index))
            }
        </div>
    )
}

export default StoresList;