import React from 'react'
import CinsaLogo from '../../components/svg/commons/cinsa_logo'

const Storytelling = ({_body}) =>{
    let data = null
    
    _body.map(item =>{
        if(item.__typename === "PrismicSpecialDealsPageBodyStorytelling"){
          return  data = item.primary
        }else return null
    })

    const title = data.storytelling_title
    const subTitle = data.storytelling_description
    return( data && 
           <div className="storytelling_container">
                <div className="container_logo--cinsa">
                <CinsaLogo
                    text_visible="false"
                    className="loading__logo"
                />
                </div>
                <div className="container_info">
                    <p className="storytelling_title"> {title.text} </p>
                    <p className="storytelling_subtitle"> {subTitle.text} </p>
                </div>
            </div>
    )
    return "storytelling"
}

export default Storytelling;