import { useStaticQuery, graphql } from "gatsby"
export const usePrismDealsPage = () => {
  const prismic = useStaticQuery(
    graphql`
            query{
                allPrismicSpecialDealsPage {
                    edges {
                      node {
                        data {
                          title {
                            text
                          }
                          campaign_name {
                            text
                          }
                          main_image {
                            url
                            lg {
                              url
                            }
                            md {
                              url
                            }
                            xs {
                              url
                            }
                          }
                          body {
                            ... on PrismicSpecialDealsPageBodyStorytelling {
                              primary {
                                storytelling_title {
                                  text
                                }
                                storytelling_description {
                                  text
                                }
                              }
                            }
                          }
                          body1 {
                            primary {
                              display_name
                              dealer_name {
                                text
                              }
                              dealer_short_name {
                                text
                              }
                              dealer_image {
                                url
                                alt
                              }
                            }
                            items {
                              location {
                                document {
                                  data {
                                    address
                                    phone
                                    state
                                    store
                                    website
                                    branchoffice
                                    coordinates {
                                      latitude
                                      longitude
                                    }
                                    document_id
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                }
            }`
  );

  return prismic.allPrismicSpecialDealsPage
}
